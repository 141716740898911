<template>
  <div>
    <p class="text-center">
      <strong> กลุ่มสินค้า </strong>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span style="float:right"><CIcon name="cil-x"></CIcon></span>
      </button>
    </p>
    <table style="width:100%;" aria-describedby="">
      <th scope="col"></th>
      <tr
        data-dismiss="modal"
        v-for="category in categories"
        :key="category.objectId"
        :value="category.objectId"
        @click="goToByCategoryLists(category.objectId)"
      >
        <td style="width:15%;padding:5px">
          <div
            class="circle"
            :style="{
              'background-color': getCategoryColor(category.indexColor),
            }"
          >
            <h3>{{ catIcon(category.name) }}</h3>
          </div>
        </td>
        <td style="padding:15px">
          {{ category.name }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import pos from '@/services/pos'
import util from '@/util/util'

export default {
  props: ['shopLineObjectId'],
  data() {
    return {
      products: [],
      categories: [],
      branchName: '',
      totally: 0,
    }
  },
  computed: {
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  created() {
    this.getCategory()
  },
  methods: {
    ...util,
    catIcon(catName) {
      return catName.charAt(0).toUpperCase()
    },
    getCategoryColor(indexColor) {
      return util.generateColor(indexColor)
    },
    goToByCategoryLists(objectId) {
      this.$router.push('/menu/' + objectId + '/category')
    },
    getCategory() {
      const uid = this.uid
      let shopObjectId = this.shopLineObjectId

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/menu/' + shopObjectId)
        .then((res) => {
          this.categories = res.data.data
        })
    },
  },
  watch: {
    shopLineObjectId(newVal, oldVal) {
      this.shopLineObjectId = newVal
      this.getCategory()
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    },
  },
}
</script>
