<template>
  <CContainer>
    <nav-bar
      v-bind:cartTotal="cartTotal"
      @update-cartTotal="updateCartTotal"
    ></nav-bar>
    <hr class="mt-0" />
    <div class="d-flex justify-content-between">
      <h5 class="mb-1 font-weight-bold">
        {{ categoryName }}
      </h5>
      <a data-toggle="modal" data-target="#categoryModal">
        <small class="text-grey">
          <CIcon name="cil-applications"></CIcon>
          กลุ่มสินค้า
        </small>
      </a>
    </div>
    <br />

    <grid-menu
      v-bind:cartTotal="cartTotal"
      @update-cartTotal="updateCartTotal"
    ></grid-menu>

    <div class="modal fade modal-p-bottom" id="categoryModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <category-lists
              v-bind:shopLineObjectId="shopLineObjectId"
            ></category-lists>
          </div>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import CategoryLists from './CategoryLists'
import NavBar from './NavBar'
import GridMenu from './GridMenu'
import '@/util/menu.css'

export default {
  components: {
    CategoryLists,
    NavBar,
    GridMenu,
  },
  data() {
    return {
      products: [],
      categoryName: '',
      cartLists: [],
      cartTotal: 0,
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    categoryObjectId() {
      return this.$route.params.categoryObjectId
    },
  },
  created() {
    this.getProduct()
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
  },
  methods: {
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    updateCartTotal(total) {
      this.cartTotal = total
    },
    goToDetail(objectId) {
      this.$router.push('/smart-menu/' + objectId + '/detail')
    },
    getProduct(page = 1) {
      const uid = this.uid

      let params = {
        shopObjectId: this.shopLineObjectId,
        categoryObjectId: this.categoryObjectId,
        page: page,
        limit: 100,
      }

      pos
        .get('/api/v1.0/' + uid + '/Shop/getproduct/data', { params })
        .then((res) => {
          this.products = res.data.data
          this.categoryName = res.data.data[0].category.name
          this.totally = this.products.length
          this.getCartTotal()
        })
    },
  },
  watch: {
    '$route.params.categoryObjectId'(val) {
      this.getProduct()
    },
  },
}
</script>
