<template>
  <div
    v-if="this.$route.name !== 'DineInMenuLists' && this.$route.name !== 'DineInPaymentGateway' && this.$route.name !== 'DineInQRPayment' && this.$route.name !== 'DineInPendingPayment'"
    class="header-qr">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex toggler-container">
        <span v-c-emit-root-event:toggle-sidebar-mobile class="text-dark p-2 pl-3">
          <div>
            <i style="font-size:x-large" class="fi fi-rr-menu-burger"></i>
          </div>
        </span>
      </div>
      <div class="flex-grow-1 text-center">
        <h4 class="text-dark  font-weight-bold">
          {{ headerName }}
        </h4>
        <small v-if="this.$route.name == 'DineInMyCart' && updated_at !== null" style="font-size:medium;"
          class="text-grey">{{ $t('updatedAtQR') }}
          {{ updated_at }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      updated_at: null
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    headerName() {
      console.log(this.$route.name)
      if (this.$route.name === 'DineInMyCart') {
        return this.$i18n.t('orderItem')
      } else if (this.$route.name === 'DineInSelectedItem') {
        return this.$i18n.t('selectedItem')
      } else {
        return this.branchName
      }
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        return this.shop.shopName
      } else {
        return ''
      }
    },
    branchName() {
      if (this.shop) {
        return this.shop.branchName
      } else {
        return ''
      }
    },
    tableName() {
      let table = JSON.parse(localStorage.getItem('table'))
      return table.name
    },
  },
  created() {
    this.updated_at = localStorage.getItem('updated_at') || null
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.updated_at = localStorage.getItem('updated_at') || null
      }, 500);
    },
  },
}
</script>

<style>
.header-qr {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  border-bottom: 2px solid #eeeeee;
  padding: 2cap;
  padding-top: 4cap;
}

.toggle-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.toggler-container {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 0;
}

.header-name {
  flex-grow: 1;
  text-align: center;
  font-size: x-large;
}
</style>
